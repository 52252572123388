import { currentAddress } from '../constants';

const authRedirectLogin = `${currentAddress}/oauth/login`;
const authRedirectRefresh = `${currentAddress}/oauth/refresh`;

const authKeys = fetch(window.env.OIDC_SIGNING_KEYS)
  .then(response => response.json())
  .then(responseJson => responseJson.keys);

const config = {
  client_id: window.env.OIDC_CLIENT_ID,
  response_type: window.env.OIDC_RESPONSE_TYPE,
  response_mode: window.env.OIDC_RESPONSE_MODE,
  scope: window.env.OIDC_SCOPE,
  authority: window.env.OIDC_AUTHORITY,
  redirect_uri: authRedirectLogin,
  // post_logout_redirect_uri
  silent_redirect_uri: authRedirectRefresh,
  checkSessionInterval: 500,
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  accessTokenExpiringNotificationTime: 120,
  loadUserInfo: false,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: false,
  signingKeys: authKeys,
};

export default config;
