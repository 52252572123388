/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import getCtotList from './actions';

const initialState = { regulationsList: [], filteredRegulationsList: [], filtersList: [] };

const filteredRegulations = (data, filters) => data.filter(rowEntry => filters.every((filterEntry) => {
  const { dataKey } = filterEntry;
  const value = filterEntry.cellDataGetter ? filterEntry.cellDataGetter({ rowData: rowEntry, dataKey }) : rowEntry[dataKey];
  return String(value).toLowerCase().startsWith(filterEntry.value.toLowerCase());
}));

const regulationsSlice = createSlice({
  name: 'regulations',
  initialState,
  reducers: {
    updateCtotFilters(state, { payload }) {
      state.filtersList = payload;
      state.filteredRegulationsList = filteredRegulations(state.regulationsList, state.filtersList);
    },
    clearCtotFilters(state) {
      state.filteredRegulationsList = state.regulationsList;
      state.filtersList = [];
    },
  },
  extraReducers: {
    [getCtotList.fulfilled](state, { payload }) {
      state.regulationsList = payload;
      state.filteredRegulationsList = filteredRegulations(state.regulationsList, state.filtersList);
    },
    [getCtotList.rejected](state, { error: { message } }) {
      state.error = message;
    },
  },
});

export const { updateCtotFilters, clearCtotFilters } = regulationsSlice.actions;
export default regulationsSlice.reducer;
