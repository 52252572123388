/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { StorageHelper } from '@atfm/utils';

import {
  addAccessTokenAndName,
  addRefreshToken,
  logout,
} from './actions';

const initialState = StorageHelper.getStorageInitialState();

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: {
    [addAccessTokenAndName.fulfilled](state) {
      state.isLoggedIn = true;
    },
    [addRefreshToken.fulfilled](state, { payload }) {
      state.refreshToken = payload;
    },
    [logout.pending]() {
      if (window.propagateSignout) window.propagateSignout();
    },
    [logout.fulfilled](state) {
      state.isLoggedIn = false;
    },
  },
});

export default sessionSlice.reducer;
