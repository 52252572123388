import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { AppProvider } from './containers/AppContext/AppContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import App from './containers/App/App';
import LoginPage from './containers/LoginPage/LoginPage';
import LoginCallback from './containers/AuthCallback/LoginCallback';
import SilentRefreshCallback from './containers/AuthCallback/SilentRefreshCallback';
import Loader from './components/ContentLoader/ContentLoader';

const Home = lazy(() => import('./containers/Home/Home'));

const routes = () => (
  <App>
    <AppProvider>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/oauth/login" component={LoginCallback} />
          <Route path="/oauth/refresh" component={SilentRefreshCallback} />
          <PrivateRoute path="*" component={Home} />
        </Switch>
      </Suspense>
    </AppProvider>
  </App>
);

export default routes;
