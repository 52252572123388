import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AtfmTheme } from '@atfm/atfm-material-ui';
import { ErrorHandler } from '@atfm/utils';

class App extends Component {
  componentDidCatch(error) {
    ErrorHandler.logReactError(error);
  }

  render() {
    return (
      <AtfmTheme>
        {this.props.children}
      </AtfmTheme>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
