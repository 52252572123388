import jwtDecode from 'jwt-decode';

export default class JwtUtils {
  static getLoginEventFromJwt(user) {
    if (user && user.access_token) {
      const decodedToken = jwtDecode(user.access_token);
      const event = {
        name: 'login',
        payload: {
          name: decodedToken.name || decodedToken.preferred_username || decodedToken.email,
        },
      };
      return event;
    }
    return null;
  }
}
