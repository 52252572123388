import { createAsyncThunk } from '@reduxjs/toolkit';

import queryTimeData from './services';

const fetchTimeData = createAsyncThunk(
  'FETCH_TIME_DATA',
  async () => queryTimeData(),
);

export default fetchTimeData;
