/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchTimeData from './actions';

const initialState = {};

const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTimeData.fulfilled](state, { payload }) {
      state.currTime = payload;
    },
    [fetchTimeData.rejected](state, { payload }) {
      state.error = payload;
    },
  },
});

export default timeSlice.reducer;
