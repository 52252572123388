import React from 'react';
import { Plane } from 'react-loader-spinner';

const ContentLoader = () => (
  <div className="centered">
    <Plane
      color="#c6c7c9"
      secondaryColor="#c6c7c9"
      height={20}
      width={20}
    />
  </div>
);

export default ContentLoader;
