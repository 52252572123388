import callApi from '../api/services';
import { ImpactPaths } from '../../constants';

const queryCtotList = (filters) => {
  const activeFilters = filters ? `?${filters}` : '';
  return callApi(
    `${ImpactPaths.DELAYS}${activeFilters}`,
    { basePath: window.env.SLOTMANAGER_SERVICE_URL },
  );
};

export default queryCtotList;
