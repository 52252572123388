import { StorageHelper } from '@atfm/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import userManager from '../../authentication/userManager';

export const addAccessTokenAndName = createAsyncThunk(
  'ADD_ACCESS_TOKEN_AND_NAME',
  async ({ accessToken, expiresAt, name }) => {
    StorageHelper.storeItem('ad_access_token', `Bearer ${accessToken}`);
    StorageHelper.storeItem('ad_access_token_expiresAt', expiresAt);
    StorageHelper.storeItem('name', name);
  },
);

export const addRefreshToken = createAsyncThunk(
  'ADD_REFRESH_TOKEN',
  async ({ refreshToken }) => {
    StorageHelper.storeItem('ad_refresh_token', refreshToken);
    return refreshToken;
  },
);

export const logout = createAsyncThunk(
  'LOGOUT',
  async () => {
    StorageHelper.clearItem('ad_access_token');
    StorageHelper.clearItem('ad_access_token_expiresAt');
    StorageHelper.clearItem('ad_refresh_token');
    StorageHelper.clearItem('name');
    userManager.revokeAccessToken();
  });
