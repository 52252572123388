export const Application = Object.freeze({
  APPLICATION_NAME: 'TopSky - Flow Manager',
  APPLICATION_VIEW: 'PUBLISHED MEASURES',
});

export const currentAddress = `${window.location.protocol}//${window.location.host}`;

const redirectToken = window.env.AUTH_REDIRECT_URI_TOKEN;
export const Authentication = Object.freeze({
  LOGIN_REDIRECT: window.env.OIDC_LOGIN_REDIRECT.replace(redirectToken, currentAddress),
});

const currentDomain = window.location.hostname.split('.').slice(1).join('.');
const env = window.env.EXECUTION_ENV === 'prod' ? '' : `-${window.env.EXECUTION_ENV}.`;
export const ApplicationPaths = Object.freeze({
  LANDING_PAGE: `https://${env}${currentDomain}`,
  // LANDING_PAGE: 'https://dev.flow.topsky.thalesdigital.io',
});

export const TimeTypes = Object.freeze({
  RUNNING: 'RUNNING',
  FIXED: 'FIXED',
});

export const Time = Object.freeze({
  MINUTE_IN_MILISECONDS: 60000,
  HOUR_IN_MILISECONDS: 3600000,
});

export const TimePaths = Object.freeze({
  TIME: '/api/0/time',
});

export const UserInfoPaths = Object.freeze({
  USERINFO: '/api/v1/userinfo',
});

export const ImpactPaths = Object.freeze({
  DELAYS: '/api/0/regulations/ctot/details',
});

export const FilterTypes = Object.freeze({
  LIST: 'list',
});
