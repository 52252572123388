import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authentication from './authentication/reducers';
import regulations from './regulations/reducers';
import time from './time/reducers';
import user from './user/reducers';

const rootReducer = combineReducers({
  session: authentication,
  regulations,
  time,
  user,
});

const initialState = {};

const thunkMiddleware = applyMiddleware(thunk);
const reduxDevToolsEnabled = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__;
const reduxMiddleware = reduxDevToolsEnabled
  ? compose(thunkMiddleware, window.__REDUX_DEVTOOLS_EXTENSION__())
  : compose(thunkMiddleware);

const store = (initialStateArg = initialState) => createStore(rootReducer, initialStateArg, reduxMiddleware);

export default store;
